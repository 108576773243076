import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
    getProducts(pagination) {
        return api.get(
            "retailers/products", { params: pagination }, { headers: authHeader() }
        );
    },
    getCategories() {
        return api.get("common/categories", { headers: authHeader() });
    },
    getFilters() {
        return api.get("common/filters", { headers: authHeader() });

    },
    getProduct(id) {
        return api.get("retailers/products/" + id, { headers: authHeader() });
    },
    getProductVariants(id) {
        return api.get(
            "retailers/products/" + id + "/variants?offset=0&count=20" + id, { headers: authHeader() }
        );
    },
    addProductToImportList(id) {
        return api.post(
            "retailers/products/" + id + "/import", {}, { headers: authHeader() }
        );
    },
    deleteProductToImportList(id) {
        return api.delete(
            "retailers/products/" + id, {}, { headers: authHeader() }
        );
    },
    backToImportList(id) {
        return api.post(
            "retailers/importlist/backtoimportlist/" + id, {}, { headers: authHeader() }
        );
    },
    getImportedProducts(pagination) {
        return api.get(
            "retailers/imported-products", { params: pagination }, {
                headers: authHeader(),
            }
        );
    },
};
